import React from "react";
import Page from "./Page";
import RegisterModal from "../components/RegisterModal";

const RegisterPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <RegisterModal />
    </Page>
  );
};

export default RegisterPage;
