import { useNavigate } from "react-router-dom";

export const useRouteHelper = () => {
  const navigate = useNavigate();
  const goLogin = () => {
    navigate("/login");
  };
  const goExperience = () => {
    navigate("/experience");
  };
  const goRegister = () => {
    navigate("/register");
  };
  const goResetPassword = () => {
    navigate("/reset-password");
  };
  const goResetPasswordEmail = (email: string) => {
    navigate("/reset-password-email?userEmail=" + email);
  };
  const goSetNewPasswordSuccess = () => {
    navigate("/set-new-password-success");
  };
  const goLoginSuccess = () => {
    navigate("/login-success");
  };
  const goVerifyAccessEmail = (email: string) => {
    navigate("/verify-access-email?userEmail=" + email);
  };
  const goAccessCode = () => {
    navigate("/access-code");
  };
  const goInvalidAccessCode = () => {
    navigate("/invalid-access-code");
  };
  return {
    goLogin,
    goExperience,
    goRegister,
    goResetPassword,
    goResetPasswordEmail,
    goSetNewPasswordSuccess,
    goLoginSuccess,
    goVerifyAccessEmail,
    goAccessCode,
    goInvalidAccessCode,
  };
};
