import React from "react";
import SetNewPasswordSuccessModal from "../components/SetNewPasswordSuccessModal";
import Page from "./Page";

const SetNewPasswordSuccessPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <SetNewPasswordSuccessModal />
    </Page>
  );
};

export default SetNewPasswordSuccessPage;
