import Modal from "./Modal";
import Button from "./Button";
import { useRouteHelper } from "../utils/RouteHelper";

const SignupSuccessModal = () => {
  const { goLogin } = useRouteHelper();
  return (
    <Modal title="You're All Set">
      <p className="text-xs text-left w-full mb-5">
        Your new account has been set up successfully. You can now start
        exploring EmpathED!
      </p>
      <Button
        text="CONTINUE"
        backgroundColor="bg-empathED-blue"
        onClick={goLogin}
      ></Button>
    </Modal>
  );
};

export default SignupSuccessModal;
