import React from "react";
import Page from "./Page";
import NewPasswordModal from "../components/NewPasswordModal";

export default function NewPasswordPage() {
  return (
    <Page bgImage="bg-login-bg">
      <NewPasswordModal />
    </Page>
  );
}
