import { useEffect, useState } from "react";
import { useRouteHelper } from "../utils/RouteHelper";
import Page from "./Page";
import { useFetchHelper } from "../utils/FetchHelper";

const ExperiencePage = () => {
  const { goLogin } = useRouteHelper();
  const [iframeSrc, setIframeSrc] = useState("");
    const { verifySession } = useFetchHelper();
  
    useEffect(() => {
        verifySession().then((isValid) => {
          if (isValid) {
            setIframeSrc(`${process.env.REACT_APP_R2_URL}/index.htm`);
          } else {
            goLogin();
          }
        });
      }, []);

  return iframeSrc ? (
    <iframe
      className="w-screen h-screen"
      src={iframeSrc}
      title="3DVista Tour"
    />
  ) : (
    <Page bgImage="bg-login-bg">
      <div className="w-screen h-screen text-5xl text-center text-white mt-40">
        Loading...
      </div>
    </Page>
  );
};

export default ExperiencePage;
