import LoginModal from "../components/LoginModal";
import Page from "./Page";

const LoginMainPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <LoginModal />
    </Page>
  );
};

export default LoginMainPage;
