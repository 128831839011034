import Modal from "./Modal";
import Button from "./Button";
import { useRouteHelper } from "../utils/RouteHelper";

const InvalidAccessCodeModal = () => {
  const { goLogin } = useRouteHelper();
  return (
    <Modal title="Invalid Access Code">
      <p className="text-xs text-left w-full mb-5">
        The access code that you have entered is invalid or expired. Please try
        a different code or contact
        <span className="text-empathED-blue font-bold ml-1">
          info@empatheducate.com
        </span>
        .
      </p>
      <Button
        text="BACK TO LOGIN"
        backgroundColor="bg-empathED-blue"
        onClick={goLogin}
      ></Button>
    </Modal>
  );
};

export default InvalidAccessCodeModal;
