import { useEffect, useState } from "react";
import { useRouteHelper } from "../utils/RouteHelper";
import Page from "./Page";

const ExperiencePage = () => {
  const { goLogin } = useRouteHelper();
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    // Check if the page is available
    fetch(`${process.env.REACT_APP_API_URL}/protected/3DVista/index.htm`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          // If the response is OK, set the iframe src
          setIframeSrc(
            `${process.env.REACT_APP_API_URL}/protected/3DVista/index.htm`,
          );
        } else if (response.status === 404) {
          // If the response is 404, redirect to the login page
          console.log("Session Not Found or Expired. " + response.status);
          goLogin();
        } else {
          throw new Error("An unexpected error occurred. " + response.status);
        }
      })
      .catch((error) => {
        console.error("Error loading iframe:", error);
        goLogin();
      });
  }, []);

  return iframeSrc ? (
    <iframe
      className="w-screen h-screen"
      src={iframeSrc}
      title="3DVista Tour"
    />
  ) : (
    <Page bgImage="bg-login-bg">
      <div className="w-screen h-screen text-5xl text-center text-white mt-40">
        Loading...
      </div>
    </Page>
  );
};

export default ExperiencePage;
