import React from "react";
import QuizModal from "../../components/QuizModal";
import Button from "../../components/Button";
import { useRouteHelper } from "../../utils/RouteHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const QuizFailPage = () => {
  const {
    goExperience,
    goADHDQuiz,
    goAnxietyQuiz,
    goDepressionQuiz,
    goDyslexiaQuiz,
  } = useRouteHelper();
  const retakeQuiz = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const title = urlParams.get("title");
    if (title) {
      if (title?.toLocaleLowerCase().includes("adhd")) {
        goADHDQuiz();
      } else if (title?.toLocaleLowerCase().includes("anxiety")) {
        goAnxietyQuiz();
      } else if (title?.toLocaleLowerCase().includes("depression")) {
        goDepressionQuiz();
      } else if (title?.toLocaleLowerCase().includes("dyslexia")) {
        goDyslexiaQuiz();
      }
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const quizScore = useSelector(
    (state: RootState) => state.quizSliceReducer.scorePercentage,
  );
  const correctAnswers = useSelector(
    (state: RootState) => state.quizSliceReducer.correctQuestions,
  );
  const totalQuestions = useSelector(
    (state: RootState) => state.quizSliceReducer.totalQuestions,
  );

  const title = urlParams.get("title") ? urlParams.get("title") : "Quiz";
  return (
    <div
      className={`bg-scroll bg-empathED-light-blue bg-cover bg-center fixed w-screen h-screen my-100`}
    >
      <div className="flex justify-center items-center w-full h-full overflow-y-auto">
        <QuizModal title={title} layout="items-left">
          <div className="flex flex-col gap-4 mb-10">
            <p className="text-left">Thank you for your submission. </p>
            <p className="text-left">
              You answered{" "}
              <span className="text-empathED-blue font-bold text-medium">
                {correctAnswers} / {totalQuestions}{" "}
              </span>
              questions correctly, and your final score is{" "}
              <span className="text-empathED-blue font-bold text-medium">
                {(quizScore * 100).toFixed(2)}%
              </span>
              . You did not pass this course because the minimum passing score
              is 80%.
            </p>
            <p className="text-left">
              Click below if you would like to retake this quiz.
            </p>
          </div>
          <div className="flex flex-grow flex-row gap-4 my-5">
            <Button
              text="RETAKE QUIZ"
              backgroundColor="bg-empathED-blue"
              onClick={retakeQuiz}
            ></Button>
            <Button
              text="BACK HOME"
              backgroundColor="bg-white"
              textColor="text-empathED-blue"
              borderColor="border-empathED-blue"
              onClick={goExperience}
            ></Button>
          </div>
        </QuizModal>
      </div>
    </div>
  );
};

export default QuizFailPage;
