import React from "react";
interface ButtonProps {
  text: string;
  backgroundColor?: string;
  textColor?: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({
  text,
  backgroundColor,
  textColor,
  onClick,
}) => {
  const baseClasses =
    "rounded-full px-10 py-1.5 text-base cursor-pointer inline-block whitespace-nowrap w-fit";
  const bgColorClass = backgroundColor || "bg-blue-500";
  const textColorClass = textColor || "text-white";
  const textSizeClass = "text-sm";

  return (
    <button
      className={`${baseClasses} ${bgColorClass} ${textColorClass} ${textSizeClass}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
