import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginMainPage from "./pages/LoginMainPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordEmailPage from "./pages/ResetPasswordEmailPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import AccessCode from "./pages/AccessCodePage";
import VerifyAccessEmailPage from "./pages/VerifyAccessEmailPage";
import InvalidAccessCodePage from "./pages/InvalidAccessCodePage";
import SignupSuccessPage from "./pages/SignupSuccessPage";
import ExperiencePage from "./pages/ExperiencePage";
import MainPage from "./pages/MainPage";
import SetNewPasswordSuccessPage from "./pages/SetNewPasswordSuccessPage";

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginMainPage />} />
          <Route path="/reset-password/" element={<ResetPasswordPage />} />
          <Route
            path="/reset-password-email"
            element={<ResetPasswordEmailPage />}
          />
          <Route path="/set-new-password" element={<NewPasswordPage />} />
          <Route
            path="/set-new-password-success"
            element={<SetNewPasswordSuccessPage />}
          />
          // TODO Enable this when we enable manual access code
          {/* <Route path="/access-code" element={<AccessCode />} />
          <Route
            path="/verify-access-email"
            element={<VerifyAccessEmailPage />}
          /> */}
          <Route
            path="/invalid-access-code"
            element={<InvalidAccessCodePage />}
          />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login-success" element={<SignupSuccessPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
