import Modal from "./Modal";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useRouteHelper } from "../utils/RouteHelper";
import { useEffect, useState } from "react";

const ResetPasswordEmailModal = () => {
  const { goLogin } = useRouteHelper();
  const [userEmail, setUserEmail] = useState("test@levralabs.com");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("userEmail");
    if (email) setUserEmail(email);
  }, []);
  return (
    <Modal title="Check Your Email">
      <p className="text-xs text-left w-full mt-2 mb-5">
        An email containing password reset instructions has been sent to
        <span className="text-empathED-blue font-bold ml-1">{userEmail}</span>.
      </p>
      <Button
        text="BACK TO LOGIN"
        backgroundColor="bg-empathED-blue"
        onClick={goLogin}
      ></Button>
    </Modal>
  );
};

export default ResetPasswordEmailModal;
