import React from "react";
interface ModalProps {
  children?: React.ReactNode;
  title?: string;
}
const Modal: React.FC<ModalProps> = ({ title, children }) => {
  return (
    <div className="relative w-full max-w-md p-20 min-h-80 bg-white rounded-xl h-fit my-20">
      <div className="relative flex flex-col items-center w-full max-w-md mx-auto">
        <div className="flex flex-col justify-center items-center gap-4 w-full">
          {title && (
            <h1 className="font-bold text-xl text-black text-left w-full -mb-2.5">
              {title}
            </h1>
          )}
          {title && (
            <div className="self-start w-20 h-0.5 bg-empathED-medium-blue mb-3.5"></div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
