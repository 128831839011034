import React from "react";
import { useRouteHelper } from "../utils/RouteHelper";
import Modal from "./Modal";
import Button from "./Button";

const SetNewPasswordSuccessModal = () => {
  const { goLogin } = useRouteHelper();
  return (
    <Modal title="You're All Set">
      <p className="text-xs text-left w-full mb-5">
        Your new password has been set successfully. You can now start exploring
        EmpathED!
      </p>
      <Button
        text="CONTINUE"
        backgroundColor="bg-empathED-blue"
        onClick={goLogin}
      ></Button>
    </Modal>
  );
};

export default SetNewPasswordSuccessModal;
