import React, { useEffect } from "react";
import { useRouteHelper } from "../utils/RouteHelper";

const MainPage = () => {
  const { goLogin, goExperience } = useRouteHelper();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/users/check-session`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          console.log("Valid Session, Login successful: ", response.status);
          goExperience();
        } else if (response.status === 404) {
          goLogin();
          console.log("Session Not Found or Expired. " + response.status);
        } else {
          throw new Error("An unexpected error occurred. " + response.status);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);
  return <div></div>;
};

export default MainPage;
