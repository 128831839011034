import React from "react";
import Page from "./Page";
import ResetPasswordEmailModal from "../components/ResetPasswordEmailModal";

const ResetPasswordEmailPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <ResetPasswordEmailModal />
    </Page>
  );
};

export default ResetPasswordEmailPage;
