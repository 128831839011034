import React, { useState, useEffect, useRef } from "react";
interface InputBoxProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  captionPosition?: string;
  caption?: React.ReactNode;
  onClick?: () => void;
  labelSize: number;
  isMatchingPassword?: boolean;
  isLoginPassword?: boolean;
  readonly?: boolean;
}
const InputBox: React.FC<InputBoxProps> = ({
  label,
  value,
  onChange,
  placeholder,
  caption,
  captionPosition = "left",
  onClick,
  labelSize,
  isMatchingPassword = false,
  isLoginPassword = false,
  readonly = false,
}) => {
  const [fontSize, setFontSize] = useState(16);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isPasswordInput = (label: string) => {
    if (
      label.toLowerCase() === "password" ||
      label.toLowerCase() === "confirm password" ||
      label.toLowerCase() === "new password" ||
      label.toLowerCase() === "confirm new password"
    )
      return true;
    return false;
  };

  const isComfirmPasswordInput = (label: string) => {
    if (
      label.toLowerCase() === "confirm password" ||
      label.toLowerCase() === "confirm new password"
    )
      return true;
    return false;
  };
  const handleChange = (newValue: string) => {
    onChange(newValue);
    if (isPasswordInput(label)) {
      setIsPasswordValid(newValue.length >= 8);
    }
  };

  useEffect(() => {
    if (labelRef.current) {
      const labelWidth = labelRef.current.offsetWidth;
      const inputWidth = labelRef.current.parentElement?.offsetWidth || 0;

      if (labelWidth > inputWidth) {
        setFontSize((prevSize) => Math.max(prevSize - 1, 12));
      } else if (fontSize < 16 && labelWidth < inputWidth * 0.9) {
        setFontSize((prevSize) => Math.min(prevSize + 1, 16));
      }
    }
  }, [label, fontSize]);

  return (
    <div className="relative flex flex-col w-full max-w-md mb-2">
      <label
        ref={labelRef}
        className="relative text-left font-normal text-black mb-1.5 transition-all duration-200 ease-in-out"
        style={{
          fontSize: `${labelSize}px`,
          whiteSpace: "normal",
          wordBreak: "break-word",
          maxWidth: "100%",
        }}
      >
        {label}
      </label>
      <input
        disabled={readonly}
        type={isPasswordInput(label) ? "password" : "text"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        placeholder={placeholder}
        className={`w-full px-3 py-2 text-gray-700 border border-empathED-medium-blue rounded-md focus:outline-none focus:ring-1 ${
          isPasswordInput(label) && !isLoginPassword
            ? !isPasswordValid && "focus:ring-red-200"
            : "focus:ring-blue-200"
        } focus:shadow-md
        } ${value && "bg-empathED-light-blue"}
        } ${
          isPasswordInput(label) &&
          !isPasswordValid &&
          isFocused &&
          !isLoginPassword &&
          "border-red-500"
        }`}
      />
      {isPasswordInput(label) &&
        !isComfirmPasswordInput(label) &&
        !isPasswordValid &&
        isFocused &&
        !isLoginPassword && (
          <p className="text-red-500 text-sm mt-1 text-left">
            Password must be longer than 8 characters.
          </p>
        )}
      {isComfirmPasswordInput(label) && !isMatchingPassword && isFocused && (
        <p className="text-red-500 text-sm mt-1 text-left">
          Passwords do not match.
        </p>
      )}
      {caption && (
        <button
          onClick={onClick}
          className={`text-xs text-empathED-blue mb-4 mt-1.5 font-bold ${
            captionPosition === "left" ? "text-left" : "text-right"
          }`}
        >
          {caption}
        </button>
      )}
    </div>
  );
};

export default InputBox;
