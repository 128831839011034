import React from "react";
import ResetPasswordModal from "../components/ResetPasswordModal";
import Page from "./Page";

const ResetPasswordPage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <ResetPasswordModal />
    </Page>
  );
};

export default ResetPasswordPage;
