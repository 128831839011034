import React from "react";

interface PageProps {
  bgImage?: string;
  children?: React.ReactNode;
}
const Page: React.FC<PageProps> = ({ children, bgImage }) => {
  return (
    <div
      className={`bg-scroll ${bgImage} bg-cover bg-center fixed w-screen h-screen`}
    >
      <div className="flex justify-center items-center w-full h-full overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default Page;
