import React from "react";
import Page from "./Page";
import InvalidAccessCodeModal from "../components/InvalidAccessCodeModal";

const InvalidAccessCodePage = () => {
  return (
    <Page bgImage="bg-login-bg">
      <InvalidAccessCodeModal />
    </Page>
  );
};

export default InvalidAccessCodePage;
